/** 个人中心 */
<template>
  <div class="personal-content content">
    <el-container>
      <el-aside width="200px" class="personal-navs mt-20 pb-50">
        <div ref="personalNavs" class="personal-navs-fixed flex-column border-r4 back-fff">
          <div class="personal-info">
            <el-avatar class="personal-avatar back-fff" :src="require('../../assets/images/my-portrait.png')"
              :size="100" fit="contain">
            </el-avatar>
            <p class="personal-members mt-10"><i>{{$store.state.userInfo.level_name}}</i></p>
            <p class="personal-phone mt-20">{{$store.state.userInfo.name || $store.state.userInfo.phone}}</p>
          </div>
          <el-menu :default-active="activeIndex" router class="el-menu-vertical-demo " background-color="#FFFFFF"
            text-color="#8490AD" active-text-color="#2974EF">
            <el-menu-item v-for="(nav,index) in personalNavs" :key="index" :index="nav.route_url"
              :route="nav.route_url">
              <i :class="nav.icon"></i>
              <span> {{nav.name}}</span>
            </el-menu-item>
          </el-menu>
        </div>
      </el-aside>
      <el-main class="personal-main ml-22 mt-20 pb-50">
        <keep-alive exclude="Daily,DailyDetails">
          <router-view></router-view>
        </keep-alive>
      </el-main>
    </el-container>
  </div>
</template>
<script>
export default {
  name: 'Personal',
  data () {
    return {
      activeIndex: this.$route.path,
      personalNavs: [
        { name: '消息中心', route_url: '/personal/notice', icon: 'el-icon-bell' },
        { name: '监控列表', route_url: '/personal/monitorList', icon: 'el-icon-tickets' },
        { name: '监控日报', route_url: '/personal/daily', icon: 'el-icon-date' },
        { name: '报告下载', route_url: '/personal/report', icon: 'el-icon-download' },
        { name: '年费监控', route_url: '/personal/annual_cost', icon: 'el-icon-document' },
        { name: '个人中心', route_url: '/personal/my', icon: 'el-icon-user' },
      ]
    }
  },
  computed: {
    userToken () {
      return this.$store.getters.userToken;
    }
  },
  watch: {
    $route (newVal) {
      this.activeIndex = newVal.meta.path || newVal.path
    },
    userToken (newVal) {
      if (!newVal) {
        this.$router.replace('/')
      }
    }
  },
  mounted () {
    this.activeIndex = this.$route.meta.path || this.$route.path
    this.clientHeight()
    window.onresize = () => {
      this.clientHeight()
    };
  },
  methods: {
    clientHeight () {
      let clientHeight = document.documentElement.clientHeight;
      if (clientHeight < 800) {
        window.addEventListener('scroll', this.scrollToTop);
      }
      if (clientHeight < 590) {
        this.$refs.personalNavs.classList.add("personal-navs-small")
      } else {
        this.$refs.personalNavs.classList.remove("personal-navs-small")
      }
    },
    scrollToTop () {
      // 变量 scrollHeight 是滚动条的总高度
      let scrollHeight = document.documentElement.scrollHeight || document.body.scrollHeight

      // 变量 windowHeight 是可视区的高度
      let windowHeight = document.documentElement.clientHeight || document.body.clientHeight

      // 变量scrollTop为当前页面的滚动条纵坐标位置
      let scrollTop = document.documentElement.scrollTop || document.body.scrollTop
      // 滚动条到底部得距离 = 滚动条的总高度 - 可视区的高度 - 当前页面的滚动条纵坐标位置
      var scrollBottom = scrollHeight - windowHeight - scrollTop
      if (scrollBottom < 200) {
        this.$refs.personalNavs.style.top = (scrollBottom - 200) + 100 + 'px'
      } else {
        this.$refs.personalNavs.style.top = 'auto'
      }
      if (scrollTop == 0) {
        this.$refs.personalNavs.style.top = 'auto'
      }
    }
  },
}
</script>
<style lang="scss" scoped>
// 左侧导航
::v-deep .personal-navs {
  min-height: 800px;
  .personal-navs-fixed {
    width: 200px;
    position: fixed;
    z-index: 10;
    &.personal-navs-small {
      max-height: calc(100% - 156px);
    }
  }
  .personal-info {
    min-height: 214px;
    text-align: center;
    color: #fff;
    font-weight: bold;
    background-image: url("../../assets/images/my-portrait-back.png");
    background-repeat: no-repeat;
    background-size: cover;
    .personal-avatar {
      margin-top: 22px;
      img {
        width: 100%;
      }
    }
    .personal-phone {
      font-size: 18px;
    }
  }
  .el-menu {
    overflow-y: auto;
    border: none;
    padding: 10px 0;
    &::-webkit-scrollbar {
      display: none;
    }
    .el-menu-item {
      height: 52px;
      font-size: 16px;
      text-align: center;
      &:hover {
        background-color: transparent !important;
        color: #2974ef !important;
      }

      &.is-active {
        font-weight: bold;
        i {
          font-weight: bold;
        }
      }
    }
  }
}
// 右侧内容
::v-deep .personal-main {
  overflow: hidden;
  padding-bottom: 20px;
  &::-webkit-scrollbar {
    display: none;
  }
  .personal-fixed,
  .monitor-list .el-tabs .el-tabs__header,
  .notice-content .el-tabs .el-tabs__header {
    width: 77%;
    background-color: #f4f5f9;
    padding: 20px 0 10px;
    position: fixed;
    top: 80px;
    z-index: 88;
  }
}
</style>